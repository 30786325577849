import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import headerBlob from '../asset/images/headerBlob.svg';

// "linear-gradient(215deg, #00d4ff 0%, #082b8f 10%, rgba(5,25,101,1) 35%, rgba(2,0,43,1) 50%, rgba(4,3,68,1) 65%, #082b8f 90%, #00d4ff 100%)"

function HomeHeader() {
    return (
        <div
            style={{
                minHeight: "100vh",
                display: "flex",
                background: "linear-gradient(215deg, #00d4ff 0%, #082b8f 5%, #031842 25%, #000 50%, #031842 75%, #082b8f 95%, #00d4ff 100%)",
                paddingBottom: "150px"
            }}
        >
            <Container className="my-auto">
                <Row>
                    <Col md={6} className="text-center"
                        style={{
                            margin: "auto", position: "relative"
                        }}
                    >
                        <img src={headerBlob} alt="blob" style={{
                            width: "100%",
                            height: "auto"
                        }} />
                        <Container
                            style={{
                                margin: "auto",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: "2"
                            }}
                        >
                            <h1 className="display-1 text-center ">
                                <span
                                    style={{
                                        color: "grey",
                                        fontWeight: "550"
                                    }}
                                >Alcor Monllor</span>
                            </h1>
                            <p className="lead text-center ">
                                Ingénieur en cybersécurité
                            </p>
                   
                        </Container>
                    </Col>
                    <Col md={6} className="item-center text-center"
                        style={{
                            margin: "auto"
                        }}
                    >
                        <p
                            style={{
                                color: "white",
                                
                            }}
                        >
                            Hello, je m'appelle Alcor, ingénieur en cybersécurité avec un double diplôme en cybersécurité et gestion des risques.
                            Passionné par la résolution de problèmes exigeants, je cherche constamment à apprendre et à appliquer des techniques de pointe pour protéger les systèmes et les données critiques.
                        </p>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}

export default HomeHeader;