import React , { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import jobExperiences from '../asset/data/jobExperiences';
import '../styles/ExpDisplay.css';



const ExpDisplay = () => {

    useEffect(() => { 
        jobExperiences.map((job, index) => {
            const img = document.getElementById(`${job.company}img`);
            if (img) {
                img.style.height = `${document.getElementById(job.company ).offsetHeight}px`;
            }
            return null;
        })
    } , [])


    return (
        <section className="py-3 py-md-5" 
        style={{ paddingTop: "0px" , marginTop : "0px"         }}
        >
            <Container
                style={{backgroundColor: "white" , paddingTop : "20px" , paddingBottom : "20px", rounded: "true" , borderRadius: "20px", marginTop: "-150px" 
                }}
                
            >
                {jobExperiences.map((job, index) => (
                    
                    <Row
                        className={`gy-3 job-row job-row-${index % 2 === 0 ?  index === 0 ? "first" : "even" : "odd"}${index === jobExperiences.length - 1 ? "-last" :""} job-row-circle${index}`}
                        key={index}
                        style={{
                           
                            paddingBottom: "10px",
                            marginTop: "0px",
                            
                            position: "relative",
                            padding: "10px",
                            

                        }}
                    >


                   


                        <Col xs={12}>

                            <Row className="gy-3" key={index}
                                style={{
                                }}>
                                {index % 2 === 1 && (
                                    <Col
                                    md={6} id={`${job.company}img`}
                                    className="d-none d-md-flex" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '100%' }}>
                                        {job.image && (
                                            <img src={job.image} alt={job.company} style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'
                                                , margin: 'auto', width :"auto", height: "auto" ,alignSelf: 'stretch'
                                            }} />
                                        )}
                                    </Col>
                                )}
                                <Col xs={12} md={6} id = {job.company}>
                                    <div>
                                        <h3>{job.role}</h3>
                                        <h4 className="text-muted">{job.company}</h4>
                                        <p>{job.duration}</p>
                                        <p>{job.description}</p>
                                    </div>

                                </Col>
                                {index % 2 === 0 && (
                                    <Col md={6} id={`${job.company}img`} className="d-none d-md-flex" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '100%' }}>
                                    {job.image && (
                                        <img src={job.image} alt={job.company} style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'
                                            
                                        }} />
                                    )}
                                </Col>
                                )}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
        </section>
    );
};

export default ExpDisplay;