import project1 from '../images/project1.jpg';


/* 
    image: project1,
    title: "Project One",
    description: "This is a description of project one.",
    link: "https://example.com/project-one"
    longDescription: "This is a long description of project one."

*/
const projectList =[
  {
    image: project1,
    title: "Project One",
    description: "This is a description of project one.",
    link: "https://example.com/project-one"
  }
]

export default projectList;