import React from 'react';
import ProjectCard from '../components/ProjectCard';
import { Container, Row, Col} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import projectList from '../asset/data/projectList';


const ProjectsPage = () => {


  return (
    <Container>
      <h2>Projects</h2>
      <Row>
        {projectList.map((project, index) => (
          <Col key={index} md={4} style={{padding:"5px"}}>
            <ProjectCard
              image={project.image} 
              title={project.title}
              description={project.description}
              link={project.link}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ProjectsPage;