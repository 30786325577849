import React from 'react';
import ProjectCard from './ProjectCard';
import { Container, Col} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import projectList from '../asset/data/projectList';
import responsive from '../asset/data/responsive';





const Projects = () => {



  return (
      <Container fluid style={{ padding: '10px', backgroundColor:'#e0e0e0' }}>
        <h2 style={{ padding: '10px', textAlign: 'center' }}>Projects</h2>
        <Carousel responsive={responsive} autoPlay={true} infinite={true} autoPlaySpeed={3000}>
          {projectList.map((project, index) => (
            <Col key={index} style={{ padding: '10px' }}>
              <ProjectCard
                image={project.image} 
                title={project.title}
                description={project.description}
                link={project.link}
              />
            </Col>
          ))}
        </Carousel>
      </Container>
    );
};



export default Projects;