import React from 'react';
import { Navbar, Nav  } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';


const NavBar = () => {
    const location = useLocation();

    return (
        <Container className="sticky-top p-0 w-100" fluid style={{margin: '0px', padding: '0px', width: '100%'}}>
            <Navbar expand="md" bg="dark" data-bs-theme="dark" className='p-2 align-items-right'>  
                <Navbar.Brand as={Link} to="/">Monllor</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Nav.Link>
                        <Nav.Link as={Link} to="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Nav.Link>
                        <Nav.Link as={Link} to="/projects" className={location.pathname === '/projects' ? 'active' : ''}>Projects</Nav.Link>
                        <Nav.Link as={Link} to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
};

export default NavBar;