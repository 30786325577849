import React from 'react';

function Contact() {
  return (
	<div>
	    <h2>Contact</h2>
	</div>
  );
}

export default Contact;