import React from 'react';
import { Card, Button } from 'react-bootstrap';

const ProjectCard = ({ title, description, link, image, CardCarousel=false}) => {
    if(CardCarousel){
        return (
            <Card className="project-card" style={{ height: '25rem' }}>
                { image && <Card.Img variant="top" src={image} style={{ maxHeight: '10rem', objectFit: 'cover'}} /> }
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{description}</Card.Text>
                    {link && (
                        <Button variant="primary" href={link} target="_blank" rel="noopener noreferrer">
                            View Project
                        </Button>
                    )}
                </Card.Body>
            </Card>
        );
    }
    return (
        <Card className="project-card" style={{ height: '25rem' }}>
            { image && <Card.Img variant="top" src={image}  style={{ maxHeight: '10rem', objectFit: 'cover'}} /> }
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
                {link && (
                    <Button variant="primary" href={link} target="_blank" rel="noopener noreferrer">
                        View Project
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};

export default ProjectCard;