import React from 'react';
import Projects from '../components/Projects';
import ExpDisplay from '../components/ExpDisplay';
import Diplome from '../components/Diplome';
import HomeHeader from '../components/HomeHeader';

function Home() {
  return (
	<div>
		<HomeHeader />

		<ExpDisplay />
		<Projects />
		<Diplome />
		
		
	</div>
  );
}

export default Home;