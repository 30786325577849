import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Diplome = () => {

    return (
        <Container fluid >
            <Row>
                <h1 style={{ padding: '10px', textAlign: 'center' }}>Parcour universitaire</h1>
            </Row>
            <Row
                style={{
                    padding: '10px', width: '100%', margin: '0'
                }} >
                <Col>
                    <h3 style={{ padding: '10px', textAlign: 'center' }}>
                    Gestion des Risques (MRI) 
                        <p className='text-muted'>
                            Diplôme d'ingénieur
                        </p>
                    </h3>
                    <p style={{ padding: '5px', textAlign: 'center' }}>
                        Le but du département Maîtrise des Risques Industriels est de former des ingénieurs capables de maîtriser les risques industriels et de contribuer à la sécurité des personnes, des biens et de l'environnement. Y compris la sécurité des systèmes d'information.
                    </p>
                    <h4 style={{ padding: '7px', textAlign: 'center' }}>
                        Option de 4ème année : Systemes Avancés
                    </h4>
                    <p style={{ padding: '5px', textAlign: 'center' }}>
                        Cette option permet d'acquérir des compétences en matière d'ingenieurie systèmes. l'ingenieurie système est une approche globale de la conception, de la réalisation et de la maintenance des systèmes complexes. Elle vise à garantir la cohérence et la compatibilité des éléments d'un système, à optimiser les performances et à minimiser les coûts. Cette approche a créé le genie logiciel par exemple.
                        
                    </p>
                    <br />
                    <p style={{ padding: '5px', textAlign: 'center', textDecoration: 'bold' }}>
                        5 ème année remplacée par un double diplôme en Sécurité et Technologies Informatiques
                    </p>
                    <h4 style={{ padding: '7px', textAlign: 'center' }}>
                        liste de references
                    </h4>
                    <div style={{ padding: '5px', textAlign: 'center' }}>
                        <a href='https://syllabus.insa-cvl.fr/fr/maquette/list?dept=3&status=1' target='_blank' rel='noreferrer'
                        >Syllabus Gestion des Risques, INSA CVL</a>

                    </div>


                </Col>
                <Col>
                    <h3 style={{ padding: '10px', textAlign: 'center' }}>
                    Sécurité Informatique (STI) 
                        <p className='text-muted'>
                            Diplôme d'ingénieur
                        </p>
                    </h3>
                    <p style={{ padding: '5px', textAlign: 'center' }}>
                        Le but du département Sécurité et Technologies Informatiques est d\'apporter les bases théoriques, technologiques et techniques nécessaires à la conception, au développement et à la mise en oeuvre de systèmes d\'information sécurisés.
                    </p>
                    <h4 style={{ padding: '7px', textAlign: 'center' }}>
                        Option de 4ème année : Sécurité Fondamental
                    </h4>
                    <p style={{ padding: '5px', textAlign: 'center' }}>
                        Cette option ce concentre sur la creation des preuvent de sécurité. Une ouverture a l'informatique quantique et l'architechture sécurité des systèmes des systeme a micronoyaux comme windows y sont abordés.
                    </p>
                    <h4 style={{ padding: '7px', textAlign: 'center' }}>
                        Option de 5ème année :  Architecture, Administration, Audit et Analyse de Sécurité
                    </h4>
                    <p style={{ padding: '5px', textAlign: 'center' }}>

                    </p>
                    <h4 style={{ padding: '7px', textAlign: 'center' }}>
                        liste de references
                    </h4>
                    <div style={{ padding: '5px', textAlign: 'center' }}>
                        <a href='https://cyber.gouv.fr/formations/ingenieur-diplome-de-linstitut-national-des-sciences-appliquees-centre-val-de-loire-1' target='_blank' rel='noreferrer'
                        >label SecNumedu, ANSSI</a>
                        <br />
                        <a href='https://syllabus.insa-cvl.fr/fr/maquette/list?dept=1&status=1' target='_blank' rel='noreferrer'
                        >Syllabus Sécurité Informatique, INSA CVL</a>

                    </div>



                </Col>
            </Row>


        </Container>
    )

};

export default Diplome;